.underlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #000000;
  opacity: 0.5;
}

.loader {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 15;
  background: rgba(255, 255, 255, 0.9);
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
